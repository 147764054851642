import hasDom from 'has-dom';

import { ownerDetailsFetcher } from 'reaxl-wallet';

import { paymentsDuckCreator } from 'axl-ducks';

import { WalletStorageService } from 'wallet-manager';

import importAuthUtilityBundle from '@/utilities/importAuthUtilityBundle';

import fordSeoUrl from '@/config/seo-config-url/branded/ford';

import PaymentCalculationFetcher from '@/fetchers/PaymentCalculationFetcher';

function getEnv() {
    let env = 'qa';
    if (hasDom()) {

        if (['www.autotrader.com', 'speedy.autotrader.com', 'www.kbb.com', fordSeoUrl.wwwUrl].includes(window?.location?.hostname)) {
            env = 'prod';
        }
    }

    return env;
}

async function getUserAccountClientAuth() {
    const { getConsumerId } = await importAuthUtilityBundle();
    return getConsumerId;
}

const walletStorageService = new WalletStorageService({
    getConsumerId: getUserAccountClientAuth(),
    environment: getEnv(),
});

const paymentsDuck = paymentsDuckCreator({
    store: 'payments',
    paymentFetcher: PaymentCalculationFetcher,
    walletStorageService,
    ownerDetailsFetcher: ownerDetailsFetcher(),
});

export default paymentsDuck;
