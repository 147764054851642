const AUTH_UTILITY_URL = 'https://auth-dist.awscconsumerinfo.autotrader.com/prod/v0/auth-utility.esm.js';

export default async function importAuthUtilityBundle() {
    const {
        getConsumerId,
        isUserSignedIn,
        once,
        showAuthModal,
    } = await import(/* webpackIgnore: true */ AUTH_UTILITY_URL);

    return {
        getConsumerId,
        isUserSignedIn,
        once,
        showAuthModal,
    };
}
